import { action, autorun, observable, set, toJS } from "mobx"
import localStorageMemory from "localstorage-memory"

const windowGlobal = typeof window !== "undefined" && window
const localAdapter = windowGlobal
  ? windowGlobal.localStorage
  : localStorageMemory
const keyPrefix = "up2us:"
const currentVersion = "2"

const autoSave = (_this, key) => {
  let firstRun = true

  autorun(() => {
    // on load check if there's an existing store on localStorage and extend the store
    if (firstRun) {
      const existingStore = localAdapter.getItem(keyPrefix + key)

      if (existingStore) {
        const parsedStore = JSON.parse(existingStore)
        if (parsedStore && parsedStore.version == currentVersion) {
          set(_this, parsedStore)
        } else {
          localAdapter.removeItem(keyPrefix + key)
        }
      }
    }

    // from then on serialize and save to localStorage
    localAdapter.setItem(keyPrefix + key, JSON.stringify(toJS(_this)))
  })

  firstRun = false
}

class ProgressStore {
  @observable curPage = -1
  @observable curSubpage = -1
  @observable maxPage = -1
  @observable maxSubpage = -1
  @observable version = 2

  constructor() {
    autoSave(this, "progress")
  }

  @action.bound
  setProgress(page, subpage) {
    this.curPage = page
    this.curSubpage = subpage
    if (this.curPage > this.maxPage) {
      this.maxPage = this.curPage
      this.maxSubpage = this.curSubpage
    } else if (this.curPage === this.maxPage) {
      if (this.curSubpage > this.maxSubpage) {
        this.maxSubpage = this.curSubpage
      }
    }
  }
}

const progress = new ProgressStore()

export { progress }
