import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import { mq } from "~/components/common/theme"
import { Box, Flex } from "reflexbox"
import { Heading } from "~/components/common/Typography"

import Burger from "~/images/burger-menu.svg"
import pages from "~/services/pages"

const calculateProgress = (myPage, mySubpage) => {
  let numPages = 0
  pages.forEach(page => {
    if (page.link) {
      numPages += 1
    } else {
      if (page.sublinks) {
        page.sublinks.forEach(sublink => {
          if (sublink.link) {
            numPages += 1
          }
        })
      }
    }
  })

  let currentPage = 1
  if (myPage === 0) currentPage = 1
  else if (myPage === 6) currentPage = numPages
  else {
    for (let i = 1; i <= myPage; i++) {
      if (myPage === i) {
        currentPage += mySubpage + 1
      } else {
        currentPage =
          currentPage + (pages[i].sublinks ? pages[i].sublinks.length : 0)
      }
    }
  }
  return Math.min(Math.floor((100 / numPages) * currentPage), 100)
}

const Header = ({
                  hideBurger,
                  headerTitle,
                  headerSubtitle,
                  hideProgress,
                  setMenuOpen,
                  menuOpen,
                  fromPage,
                  fromSubpage,
                }) => {
  const data = useStaticQuery(graphql`
      query {
          adidasLogo: file(name: { eq: "adidas-logo" }) {
              childImageSharp {
                  fluid(maxHeight: 80) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          up2usLogo: file(name: { eq: "up2us-logo-new" }) {
              childImageSharp {
                  fluid(maxHeight: 80) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
      }
  `)

  const { adidasLogo, up2usLogo } = data
  const progressInPct = calculateProgress(fromPage, fromSubpage)

  return (
    <Box
      as={"header"}
      flexDirection={"column"}
      height={"fit-content"}
      css={css`
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
        z-index: 1;
      `}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        px={["16px", "32px"]}
        bg="black"
        height={["54px", "80px"]}
        minHeight={["54px", "80px"]}
        css={css`
          position: relative;
        `}
      >
        <Flex alignItems="center">
          <Box
            css={mq({
              height: [20, 48],
              minHeight: [20, 48],
              width: [30, 72],
              minWidth: [30, 72],
            })}
          >
            <a
              href={"https://www.adidas.com/us/shebreaksbarriers"}
              target={"_blank"}
            >
              <Img
                fluid={adidasLogo.childImageSharp.fluid}
                alt="Adidas Logo"
                css={css`
                  height: 100%;
                  width: 100%;
                `}
                loading={"eager"}
              />
            </a>
          </Box>
          <Box
            css={mq({
              height: [20, 48],
              minHeight: [20, 48],
              width: [30, 76],
              minWidth: [30, 76],
              marginLeft: [12, 22],
            })}
          >
            <a href={"https://www.up2us.org/"} target={"_blank"}>
              <Img
                fluid={up2usLogo.childImageSharp.fluid}
                alt="UP2US Logo"
                css={css`
                  height: 100%;
                  width: 100%;
                `}
                loading={"eager"}
              />
            </a>
          </Box>
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="center"
          css={[
            css`
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: fit-content;
            `,
            mq({
              maxWidth: ["40%", "45%", "60%"],
            }),
          ]}
        >
          <Heading
            color="white"
            fontSize={["12px", "14px", "20px"]}
            lineHeight={["14px", "16px", "22px"]}
            my="0"
            fontFamily="AdineuePROCondRegular"
          >
            {headerSubtitle}
          </Heading>
          <Heading
            color="white"
            fontSize={["12px", "20px", "30px"]}
            lineHeight={["14px", "24px", "25px"]}
            my="0"
            fontFamily="AdineuePROBlackWeb"
          >
            {headerTitle}
          </Heading>
        </Flex>
        {!hideBurger && (
          <Box width={["30px", "60px"]} height={["30px", "60px"]}>
            <a
              href={"#"}
              onClick={() => {
                setMenuOpen(!menuOpen)
              }}
              css={css`
                width: 100%;
                height: 100%;
              `}
            >
              <Burger alt="Menu"></Burger>
            </a>
          </Box>
        )}
      </Flex>
      {!hideProgress && (
        <Flex
          height={["20px", "30px"]}
          minHeight={["20px", "30px"]}
          alignItems={"center"}
          css={p => css`
            background: linear-gradient(
              to right,
              ${p.colors.yellow} 0%,
              ${p.colors.yellow} ${progressInPct}%,
              ${p.colors["grey-progress"]} ${progressInPct}%,
              ${p.colors["grey-progress"]} 100%
            );
          `}
        >
          <Heading
            fontSize={["12px", "14px"]}
            lineHeight={0}
            fontFamily={"AdineuePROCondRegular"}
            pl={["20px", "30px"]}
          >
            CURRICULUM: {progressInPct}% Complete
          </Heading>
        </Flex>
      )}
    </Box>
  )
}

export default Header
