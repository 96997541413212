import React from "react"
import Helmet from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import { Location } from "@reach/router"

function SEO({ title, lang = "en", description = ``, meta = [], location }) {
  const { site, ogimage, twitterShare } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        ogimage: file(name: { eq: "square-share" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              src
            }
          }
        }
        twitterShare: file(name: { eq: "twitter-share" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              src
            }
          }
        }
      }
    `
  )

  const metaDescription = site.siteMetadata.description
  const img = site.siteMetadata.siteUrl + ogimage.childImageSharp.fluid.src
  const twitterImg =
    site.siteMetadata.siteUrl + twitterShare.childImageSharp.fluid.src

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defaultTitle="Keep Girls in Sport"
      title={site.siteMetadata.title}
      titleTemplate={`${site.siteMetadata.title}`}
      meta={[
        {
          property: `image`,
          content: img,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: img,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: "@adidas",
        },
        {
          name: `twitter:title`,
          content: site.siteMetadata.title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: twitterImg,
        },
      ].concat(meta)}
    />
  )
}

export default props => (
  <Location>{locationProps => <SEO {...locationProps} {...props} />}</Location>
)
