import React from "react"
import { css } from "@emotion/core"

export default function OutsideLink({ link, children, color }) {
  return (
    <a
      href={link}
      target={"_blank"}
      css={p => css`
        color: ${color ? p.colors[color] : "inherit"};
        text-decoration: none;
        cursor: pointer;
        :hover {
          text-decoration: underline;
        }
      `}
    >
      {children}
    </a>
  )
}
