import React, { useEffect, useState } from "react"
import { graphql, navigate, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Modal from "react-modal"
import { css } from "@emotion/core"
import { Box, Flex } from "reflexbox"
import { Heading, Text } from "~/components/common/Typography"
import Button from "~/components/common/Button"
import { mq } from "~/components/common/theme"
import { rgba } from "polished"
import { inject, observer } from "mobx-react"
import pages from "~/services/pages"
import localStorageMemory from "localstorage-memory"
import CloseSvg from "~/images/close.svg"

Modal.setAppElement("#___gatsby")

const windowGlobal = typeof window !== "undefined" && window
const localSessionAdapter = windowGlobal
  ? windowGlobal.sessionStorage
  : localStorageMemory
const modalKey = "up2us:modalShown"

const customStyles = {
  overlay: {
    zIndex: 10,
    overflow: "auto",
    backgroundColor: rgba("black", 0.5),
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-45%",
    padding: 0,
    border: 0,
    transform: "translate(-50%, -50%)",
    overflow: "auto",
  },
}
const WelcomeBack = inject("progress")(
  observer(({ isOpen, progress, disable }) => {
    const data = useStaticQuery(graphql`
      query {
        back: file(name: { eq: "LandingPage" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        backMobile: file(name: { eq: "LandingPage" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)

    if (disable) {
      localSessionAdapter.setItem(modalKey, "true")
    }

    const [modalShown, setModalShown] = useState(
      localSessionAdapter.getItem(modalKey) != "true" && disable == false
    )

    useEffect(() => {
      const keyFromSession = localSessionAdapter.getItem(modalKey)
      setModalShown(keyFromSession != "true")
    })

    const onContinue = () => {
      try {
        localSessionAdapter.setItem(modalKey, "true")
        if (pages[progress.maxPage].link) {
          navigate(pages[progress.maxPage].link)
        } else {
          navigate(pages[progress.maxPage].sublinks[progress.maxSubpage].link)
        }
      } catch (e) {
        navigate("/")
      } finally {
        setModalShown(false)
      }
    }

    const onReset = () => {
      try {
        localSessionAdapter.setItem(modalKey, "true")
        navigate("/")
      } finally {
        setModalShown(false)
      }
    }

    const onClose = () => {
      try {
        localSessionAdapter.setItem(modalKey, "true")
      } finally {
        setModalShown(false)
      }
    }

    const { back, backMobile } = data

    const mqMobile = mq({
      display: ["block", "none"],
    })
    const mqNonMobile = mq({
      display: ["none", "block"],
    })

    const objFit = `cover`
    const objPosition = `50% 50%`
    const fontFamily = `"object-fit: ${objFit}; object-position: ${objPosition}"`

    const polyfillStyles = {
      objectFit: objFit,
      objectPosition: objPosition,
      fontFamily: fontFamily,
    }

    return (
      <Modal
        isOpen={isOpen && modalShown}
        contentLabel="Welcome Back Modal"
        style={customStyles}
      >
        <Box
          css={css`
            position: absolute;
            top: 20px;
            right: 20px;
            width: 22px;
            height: 22px;
            min-height: 22px;
            min-width: 22px;
            cursor: pointer;
            z-index: 1;
          `}
          onClick={onClose}
        >
          <CloseSvg
            css={css`
              height: 100%;
              width: 100%;
            `}
          />
        </Box>
        <Flex
          flexDirection={["column", "row"]}
          minWidth={["unset", "700px", "765px"]}
        >
          <Box width={["100%", "50%"]}>
            <Img
              fluid={{
                ...backMobile.childImageSharp.fluid,
                aspectRatio: 2880 / 1644,
              }}
              margin={"0 auto"}
              loading={"eager"}
              css={[
                css`
                  width: 100%;
                `,
                mqMobile,
              ]}
            ></Img>
            <Img
              fluid={{
                ...back.childImageSharp.fluid,
                aspectRatio: 1 / 1.5,
              }}
              margin={"0 auto"}
              loading={"eager"}
              imgStyle={{ ...polyfillStyles }}
              css={[
                css`
                  width: 100%;
                `,
                mqNonMobile,
              ]}
            ></Img>
          </Box>
          <Flex
            width={["100%", "50%"]}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            textAlign={"center"}
            p={6}
          >
            <Heading>Welcome Back</Heading>
            <Box width={"100%"}>
              <Text>
                Would you like to continue where you left off or start from the
                beginning?
              </Text>
            </Box>
            <Box mt={6}>
              <Button width={"100%"} onClick={onContinue}>
                CONTINUE
              </Button>
              <Button
                width={"100%"}
                mt={6}
                px={5}
                bg={"white"}
                css={css`
                  color: black;
                  border: 2px black solid;
                `}
                onClick={onReset}
              >
                START FROM BEGINNING
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Modal>
    )
  })
)

export default WelcomeBack
