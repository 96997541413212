import facepaint from "facepaint"
import { rgba } from "polished"

const sizes = ["1200px", "800px", "900px", "704px"]
sizes.desktop = sizes[0]
sizes.mediumContent = sizes[1]
sizes.blockText = sizes[2]
sizes.tablet = sizes[3]

const theme = {
  breakpoints: ["768px", "1024px", "1440px"],
  fontSizes: [12, 14, 16, 18, 24, 32, 48, 64, 96, 128],
  lineHeights: [0, 0.5, 0.75, 1, 1.25, 1.5, 2],
  space: [0, 1, 2, 4, 8, 16, 32, 64, 128, 256],
  sizes: sizes,
  colors: {
    yellow: "#F3FD59",
    red: "#FF3C2F",
    black: "#000000",
    seafoam: "#AADBCF",
    grey: rgba(255, 255, 255, 0.7),
    "white-panel": rgba("white", 0.8),
    "grey-progress": "#C8CBCC",
    "grey-text": "#000000", //It is black, but first it was gray and this was a quick fix after qa before deadline...
  },
}

const mq = facepaint(theme.breakpoints.map(bp => `@media (min-width: ${bp})`))

export { theme, mq }
