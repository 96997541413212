import React from "react"
import { progress } from "~/services/mobxStore"

const withProgress = (Page, myPage, mySubpage, storeProgress = true) => {
  return () => {
    if (storeProgress) {
      progress.setProgress(myPage, mySubpage)
    }

    return <Page myPage={myPage} mySubpage={mySubpage}/>
  }
}

export default withProgress
