import React from "react"
import shouldForwardProp from "@styled-system/should-forward-prop"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import {
  color,
  display,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  maxWidth,
  position,
  space,
  textAlign,
  width,
} from "styled-system"
import { mq } from "~/components/common/theme"

const NoBreakingWords = ({ children, minWords = 2 }) => {
  let finalText = ""
  if (typeof children === "string" || children instanceof String) {
    try {
      const textParts = children ? children.split(" ") : [""]
      if (textParts.length === 4) {
        minWords = 2 //forcing this so that the first line does not end up with one word
      }
      if (textParts.length < 4) {
        finalText = textParts.join(" ").trim()
      } else {
        for (let i = 0; i < textParts.length; i++) {
          if (i > textParts.length - minWords) {
            finalText = finalText + "\u00A0"
          } else {
            finalText = finalText + " "
          }
          finalText += textParts[i]
        }
      }
    } catch (e) {
      //fallback to doing nothing
      finalText = children
    }
  } else {
    finalText = children
  }
  return <>{finalText}</>
}

const Base = styled("div", { shouldForwardProp })`
  ${space}
  ${fontSize}
  ${fontFamily}
  ${fontWeight}
  ${lineHeight}
  ${color}
  ${width}
  ${maxWidth}
  ${display}
  ${textAlign}
  ${position}
  word-wrap: break-word; 
`

const uppercase = css`
  text-transform: uppercase;
`

const defaultTextStyle = css``

const TextStyled = styled(Base, { shouldForwardProp })`
  ${defaultTextStyle};
  ${props => (props.uppercase ? uppercase : null)}
`.withComponent("p")

const Text = ({ children, doBreak, ...props }) => (
  <TextStyled
    fontFamily={"AdineuePRORegularWeb"}
    color={"grey-text"}
    {...props}
  >
    {doBreak && children}
    {!doBreak && <NoBreakingWords>{children}</NoBreakingWords>}
  </TextStyled>
)

const UlStyled = styled(Base, { shouldForwardProp })`
  ${defaultTextStyle};
  list-style-position: outside;
  list-style-type: disc;
  ${props => (props.uppercase ? uppercase : null)}
`.withComponent("ul")

const Ul = ({ children, ...props }) => (
  <UlStyled fontFamily={"AdineuePRORegularWeb"} color={"grey-text"} {...props}>
    {children}
  </UlStyled>
)

const headBackgroundColor = p => {
  if (p.background) {
    const color = p.theme.colors[p.background]
      ? p.theme.colors[p.background]
      : p.background
    const horizontalSpace = "20px"
    const backgroundColor = css`
      background-color: ${color};
      box-decoration-break: clone;
    `

    try {
      let y = 0
      let fontSize = p.fontSize
      let lineHeight = p.lineHeight
      if (fontSize && lineHeight) {
        if (typeof fontSize === "string") {
          fontSize = [fontSize]
          lineHeight = [lineHeight]
        }
      }

      let boxShadow = []
      for (let i = 0; i < fontSize.length; i++) {
        let l = parseInt(lineHeight[i].substring(0, lineHeight[i].length - 2))
        let f = parseInt(fontSize[i].substring(0, fontSize[i].length - 2))

        y = Math.floor((l - f) / 2 / 2)
        if ((f > 31 && l - f < 15) || l - f < 10) {
          if (y <= 2) y = 0
          if (y == 3) y = 0
        }

        let verticalSpace = `${y}px`
        boxShadow.push(`
        -${horizontalSpace} 0 0 ${verticalSpace} ${color},
        ${horizontalSpace} 0 0 ${verticalSpace} ${color},
        0 0 0 ${verticalSpace} ${color}`)
      }

      const boxShadowMq = mq({ boxShadow })
      return [backgroundColor, boxShadowMq]
    } catch (ex) {
      return backgroundColor
    }
  } else {
    return css``
  }
}

const HeadingStyled = styled(Base, { shouldForwardProp })`
  text-transform: uppercase;
  word-wrap: break-word;
`.withComponent("h1")

const HeadingSpanStyled = styled("span", { shouldForwardProp })`
  ${headBackgroundColor};
`

const Heading = ({ children, ...props }) => {
  return (
    <HeadingStyled
      fontSize={5}
      lineHeight={4}
      display={"inline-block"}
      fontFamily={"AdineuePROBlackWeb"}
      textAlign={"center"}
      m={0}
      {...props}
    >
      <HeadingSpanStyled {...props} as={"span"}>
        <NoBreakingWords>{children}</NoBreakingWords>
      </HeadingSpanStyled>
    </HeadingStyled>
  )
}

export { Text, Heading, Ul, NoBreakingWords }
