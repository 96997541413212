import { mq } from "~/components/common/theme"
import { css } from "@emotion/core"
import { Box, Flex } from "reflexbox"
import CheckedSvg from "~/images/checked.svg"
import LockSvg from "~/images/padlock.svg"
import { Text } from "~/components/common/Typography"
import MinusSvg from "~/images/minus.svg"
import PlusSvg from "~/images/plus.svg"
import React from "react"
import { Link } from "gatsby"
import cx from "clsx"
import Collapse from "@kunukn/react-collapse/dist/Collapse.umd"
import { inject, observer } from "mobx-react"
import { buildStyles, CircularProgressbar } from "react-circular-progressbar"

const svgMq = mq({
  height: ["18px", "18px", "22px"],
  width: ["18px", "18px", "22px"],
  minWidth: ["18px", "18px", "22px"],
})

const Circle = ({ percent }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      css={[
        css`
          //border: 1.5px solid white;
          //border-radius: 50%;
        `,
        svgMq,
      ]}
    >
      <circle r="9" cx="10" cy="10" stroke="white" strokeWidth="2" />
      <circle
        r="5.0"
        cx="10"
        cy="10"
        fill="transparent"
        stroke="white"
        strokeWidth="10"
        strokeDasharray={`calc(${percent} * 31.5 / 100) 31`}
        transform="rotate(-90) translate(-20)"
      />
    </svg>
  )
}

const StatusIcon = ({ status }) => {
  return (
    <Box mr={5} css={[svgMq]}>
      {status.status === "complete" && (
        <CheckedSvg
          css={[
            css`
              fill: white;
              width: 100%;
              height: 100%;
            `,
          ]}
        />
      )}
      {status.status === "lock" && (
        <LockSvg
          css={[
            css`
              fill: white;
              width: 100%;
              height: 100%;
            `,
          ]}
        />
      )}
      {status.status === "current" && (
        <div>
          <CircularProgressbar
            css={[
              css`
                background-color: white;
                border-width: 2px;
                border-color: white;
                border-style: solid;
                border-radius: 55%;
              `,
              svgMq,
            ]}
            value={status.progress}
            strokeWidth={50}
            styles={buildStyles({
              strokeLinecap: "butt",
              backgroundColor: "#fff",
              pathColor: "#ffffff",
              trailColor: "#000",
            })}
          />
        </div>
        // <Circle percent={status.progress} css={[css``]} />
      )}
    </Box>
  )
}

const AccordionHeader = ({
  bold,
  heading,
  onToggle,
  isOpen,
  disabled,
  noStatus,
  status,
  ...props
}) => {
  const additionaProps = {}
  if (onToggle) {
    additionaProps.onClick = onToggle
  }
  return (
    <div
      disabled={disabled}
      aria-expanded={isOpen}
      {...additionaProps}
      className="accordion__toggle"
      css={css`
        border: none;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        background-color: black;
        color: white;
        /* setting padding gives better anim than setting height */
        padding-top: 5px;
        padding-bottom: 5px;
        position: relative;
        width: 100%;
        text-align: left;
        outline: none;
        opacity: ${!noStatus && status.status == "lock" ? 0.5 : 1};
      `}
    >
      <Flex alignItems={"center"} {...props} width={"100%"}>
        <Flex width={"100%"} alignItems={"center"}>
          {!noStatus && <StatusIcon status={status} />}
          <Box width={"100%"}>
            {bold && (
              <Text
                uppercase
                fontSize={["18px", "24px"]}
                fontFamily={"AdineuePROBoldWeb"}
                color={"white"}
              >
                {heading}
              </Text>
            )}
            {!bold && (
              <Text
                fontSize={["12px", "12px", "18px"]}
                lineHeight={["14px", "14px", "21px"]}
                color={"white"}
                css={css`
                  text-transform: uppercase;
                `}
              >
                {heading}
              </Text>
            )}
          </Box>
        </Flex>
        {!disabled && (
          <Box css={[svgMq]} ml={5}>
            {isOpen ? <MinusSvg /> : <PlusSvg />}
          </Box>
        )}
        <div
          className="accordion__divider"
          css={css`
            position: absolute;
            top: 0px;
            height: 1px;
            left: 20px;
            right: 20px;
            background: #e4e5e4;
            transition: 260ms;
            opacity: ${isOpen ? 0 : 1};
            display: none;
          `}
        />
      </Flex>
    </div>
  )
}
const BoldHeader = ({ ...props }) => (
  <AccordionHeader bold noStatus px={6} height={["54px", "64px"]} {...props} />
)
const Accordion = ({
  boldHeader,
  children,
  isOpen,
  heading,
  onToggle,
  transitionDuration,
  disabled,
  link,
  status,
  ...props
}) => {
  const headerProps = {
    isOpen,
    onToggle,
    transitionDuration,
    heading,
    disabled,
    status,
  }
  return (
    <Box
      className={cx("accordion", {
        "accordion--is-open": isOpen,
        "accordion--is-closed": !isOpen,
      })}
      css={css`
        position: relative;
        width: 100%;
        cursor: pointer;
        transition: ${transitionDuration};
      `}
    >
      {boldHeader && <BoldHeader {...headerProps} />}
      {!boldHeader && link && status.status != "lock" && (
        <Link
          to={link}
          css={css`
            text-decoration: none;
            text-decoration-color: white !important;

            &:hover {
              text-decoration: underline;
            }
          `}
        >
          <AccordionHeader {...headerProps} onToggle={null} />
        </Link>
      )}
      {!boldHeader && (!link || status.status == "lock") && (
        <AccordionHeader {...headerProps} />
      )}
      <Collapse
        isOpen={isOpen}
        style={{ transitionDuration }}
        aria-hidden={isOpen ? "false" : "true"}
      >
        {children}
      </Collapse>
    </Box>
  )
}

const AccordionLinkText = ({ text, current }) => (
  <Text
    fontFamily={current ? "AdineuePROBoldWeb" : "AdineuePRORegularWeb"}
    fontSize={["12px", "12px", "18px"]}
    lineHeight={["14px", "14px", "21px"]}
    color={"white"}
    css={css`
      text-transform: uppercase;
    `}
  >
    {text}
  </Text>
)

export const AccordionLinks = inject("progress")(
  observer(({ links, status, page, progress }) => {
    return (
      <Flex
        flexDirection={"column"}
        my={3}
        pl={["45px", "45px", "60px"]}
        pr={3}
        width={"100%"}
        css={css`
          opacity: ${status.status == "lock" ? 0.5 : 1};
        `}
      >
        {links &&
          links.map((link, index) => {
            if (!link.hideMenu)
              return (
                <Box key={link.title + "-sublink"}>
                  {status.status != "lock" && (
                    <Link
                      to={link.link}
                      css={css`
                        text-decoration: none;
                        text-decoration-color: white !important;

                        &:hover {
                          text-decoration: underline;
                        }
                      `}
                    >
                      <Box width={"100%"}>
                        <AccordionLinkText
                          text={link.title}
                          current={
                            progress.curPage == page &&
                            progress.curSubpage == index
                          }
                        />
                      </Box>
                    </Link>
                  )}
                  {status.status == "lock" && (
                    <AccordionLinkText
                      text={link.title}
                      current={
                        progress.curPage == page && progress.curSubpage == index
                      }
                    />
                  )}
                </Box>
              )
            else return null
          })}
      </Flex>
    )
  })
)
export { Accordion }
export { AccordionHeader }
