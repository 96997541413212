import React from "react"
import { css } from "@emotion/core"
import { Flex } from "reflexbox"

import AttnLogo from "~/images/attn-logo-navy.svg"
import { Text } from "~/components/common/Typography"
import OutsideLink from "~/components/common/OutsideLink"

const Copyright = ({ ...props }) => {
  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"center"}
      px="3"
      mx={"auto"}
      pb={5}
      {...props}
    >
      <a
        target={"_blank"}
        href={"https://www.attn.com"}
        css={css`
          text-decoration: none;
          cursor: pointer;
          :hover {
            text-decoration: underline;
            text-decoration-color: black;
          }
        `}
      >
        <Flex
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Text
            fontFamily={"AdineuePROCondRegular"}
            fontSize={["14px"]}
            lineHeight={["16px"]}
            color={"black"}
            mr={4}
          >
            POWERED BY
          </Text>
          <AttnLogo
            css={css`
              width: 74px;
              height: 24px;
            `}
          />
        </Flex>
      </a>
      <Text
        fontFamily={"AdineuePRORegularWeb"}
        fontSize={["14px"]}
        lineHeight={["16px"]}
        textAlign={"center"}
      >
        <OutsideLink
          link={"https://www.adidas.com/us/what-is-the-privacy-policy.html"}
        >
          Privacy Policy
        </OutsideLink>{" "}
        |{" "}
        <OutsideLink
          link={
            "https://www.adidas.com/us/what-are-the-terms-and-conditions.html"
          }
        >
          Terms & Conditions
        </OutsideLink>{" "}
        | © 2019 adidas America Inc.
        <br />© UP2US 2019
      </Text>
    </Flex>
  )
}

export default Copyright
