import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import { Flex } from "reflexbox"

import Button from "~/components/common/Button"
import LeftArrow from "~/images/left-arrow.svg"

const Btn = ({ link, children, ...props }) => {
  const StyledBtn = (
    <Button
      fontSize={["12px", "14px"]}
      lineHeight={["14px", "16px"]}
      disabled={link ? false : true}
      {...props}
    >
      {children}
    </Button>
  )
  return link ? (
    <Link
      css={css`
        text-decoration: none;
        text-decoration-color: white !important;

        &:hover {
          text-decoration: none;
        }
      `}
      to={link}
    >
      {StyledBtn}
    </Link>
  ) : (
    StyledBtn
  )
}

const Footer = ({
  nextText,
  nextLink,
  backLink,
  scrolledToBottom,
  hideNext,
}) => {
  return (
    <Flex
      as="footer"
      px={["13px", "32px"]}
      css={p => [
        css`
          background-color: ${p.colors.yellow};
          height: 80px;
          min-height: 80px;
        `,
      ]}
    >
      <Flex
        maxWidth="desktop"
        mx="auto"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Btn link={backLink} px={["5", "6", "7"]} mr={5}>
          <LeftArrow
            css={css`
              width: 24px;
              min-width: 24px;
              height: 24px;
            `}
          ></LeftArrow>
        </Btn>
        {!hideNext && (
          <Btn
            link={scrolledToBottom ? nextLink : null}
            leftBackground
            minWidth={["258px", "400px"]}
            px={[3, 4]}
          >
            <span>{nextText ? nextText : "Next"}</span>
          </Btn>
        )}
      </Flex>
    </Flex>
  )
}

export default Footer
