const pages = [
  {
    title: "INTRODUCTION: WHY IT MATTERS",
    link: "/introduction-why-it-matters/",
    disabled: true,
  },
  {
    title: "LESSON ONE: THE POWER OF RELATIONSHIPS",
    sublinks: [
      {
        title: "A. INTRODUCTION",
        link: "/1-the-power-of-relationships/a-introduction/",
      },
      {
        title: "B. INFORMAL TIME",
        link: "/1-the-power-of-relationships/b-informal-time/",
      },
      {
        title: "C. COACH-TO-ATHLETE RELATIONSHIPS",
        link: "/1-the-power-of-relationships/c-coach-to-athlete-relationships/",
      },
      {
        title: "D. ATHLETE-TO-ATHLETE RELATIONSHIPS",
        link:
          "/1-the-power-of-relationships/d-athlete-to-athlete-relationships/",
      },
      {
        title: "E. QUIZ",
        link: "/1-the-power-of-relationships/quiz-1/",
        quizLink: "/1-the-power-of-relationships/quiz",
        hideMenu: true,
      },
    ],
  },
  {
    title: "LESSON TWO: SAFE SUPPORTIVE CULTURE",
    sublinks: [
      {
        title: "A. INTRODUCTION",
        link: "/2-safe-supportive-culture/a-introduction/",
      },
      {
        title: "B. IT STARTS WITH SAFETY",
        link: "/2-safe-supportive-culture/b-it-starts-with-safety/",
      },
      {
        title: "C. SETTING NON-NEGOTIABLES AND UNDERSTANDING CONTEXT",
        link:
          "/2-safe-supportive-culture/c-setting-non-negotiables-and-understanding-context/",
        buttonText: "Setting Non-Negotiables",
      },
      {
        title: "D. CULTIVATING FRIENDSHIP",
        link: "/2-safe-supportive-culture/d-cultivating-friendship/",
      },
      {
        title: "E. TRADITIONS WITH TEAM TIME",
        link: "/2-safe-supportive-culture/e-traditions-with-team-time/",
      },
      {
        title: "F. OPPORTUNITIES FOR REAL CONTRIBUTION",
        link:
          "/2-safe-supportive-culture/f-opportunities-for-real-contribution/",
      },
      {
        title: "G. QUIZ",
        link: "/2-safe-supportive-culture/quiz-1/",
        quizLink: "/2-safe-supportive-culture/quiz",
        hideMenu: true,
      },
    ],
  },
  {
    title: "LESSON THREE: THE IMPACT OF STRESS ON THE BRAIN AND BODY",
    sublinks: [
      {
        title: "A. INTRODUCTION",
        link: "/3-the-impact-of-stress-on-the-brain-and-body/a-introduction/",
      },
      {
        title: "B. BRAIN 101",
        link: "/3-the-impact-of-stress-on-the-brain-and-body/b-brain-101/",
      },
      {
        title: "C. THE STORY OF STRESS",
        link:
          "/3-the-impact-of-stress-on-the-brain-and-body/c-the-story-of-stress/",
      },
      {
        title: "D. BEHAVIOR TELLS A STORY",
        link:
          "/3-the-impact-of-stress-on-the-brain-and-body/d-behavior-tells-a-story/",
      },
      {
        title: "E. GROWTH MINDSET AND REWIRING THE BRAIN",
        link:
          "/3-the-impact-of-stress-on-the-brain-and-body/e-growth-mindset-and-rewiring-the-brain/",
      },
      {
        title: "F. QUIZ",
        link: "/3-the-impact-of-stress-on-the-brain-and-body/quiz-1/",
        quizLink: "/3-the-impact-of-stress-on-the-brain-and-body/quiz",
        hideMenu: true,
      },
    ],
  },
  {
    title: "LESSON FOUR: EVERYTHING IS A SKILL",
    sublinks: [
      {
        title: "A. INTRODUCTION",
        link: "/4-everything-is-a-skill/a-introduction/",
      },
      {
        title: "B. TEACHING LIFE SKILLS THROUGH SPORTS",
        link: "/4-everything-is-a-skill/b-teaching-life-skills-through-sports/",
      },
      {
        title: "C. ADVANCED COACHING SKILLS",
        link: "/4-everything-is-a-skill/c-advanced-coaching-skills/",
      },
      {
        title: "D. PLANNING FOR SUCCESS",
        link: "/4-everything-is-a-skill/d-planning-for-success/",
      },
      {
        title: "E. QUIZ",
        link: "/4-everything-is-a-skill/quiz-1/",
        quizLink: "/4-everything-is-a-skill/quiz",
        hideMenu: true,
      },
    ],
  },
  {
    title: "LESSON FIVE: FIT FOR LIFE",
    sublinks: [
      { title: "A. INTRODUCTION", link: "/5-fit-for-life/a-introduction/" },
      {
        title: "B. FIT FOR LIFE - TODAY",
        link: "/5-fit-for-life/b-fit-for-life-today/",
      },
      {
        title: "C. MENTAL HEALTH",
        link: "/5-fit-for-life/c-mental-health/",
      },
      {
        title: "D. SOCIAL WELL-BEING",
        link: "/5-fit-for-life/d-social-well-being/",
      },
      {
        title: "E. KEEP GIRLS HEALTHY AND IN THE GAME",
        link: "/5-fit-for-life/e-keep-girls-healthy-and-in-the-game/",
      },
      {
        title: "F. FIT FOR LIFE — IN THE FUTURE",
        link: "/5-fit-for-life/f-in-the-future/",
      },
      {
        title: "G. QUIZ",
        link: "/5-fit-for-life/quiz-1/",
        quizLink: "/5-fit-for-life/quiz",
        hideMenu: true,
      },
    ],
  },
  {
    title: "CONCLUSION: SPORTS & SOCIETY",
    link: "/conclusion-sports-and-society/",
    disabled: true,
  },
  {
    title: "KEEP GIRLS IN SPORT: RESOURCES",
    link: "/resources/",
    disabled: true,
    hideMenu: true,
  },
  {
    title: "KEEP GIRLS IN SPORT: ABOUT",
    link: "/about/",
    disabled: true,
    hideMenu: true,
  },
]

const getNextLink = (fromPage, fromSubpage) => {
  if (fromSubpage === undefined) return pages[fromPage + 1].sublinks[0].link

  if (!pages[fromPage].sublinks[fromSubpage + 1]) {
    if (pages[fromPage + 1].link) return pages[fromPage + 1].link
    else return pages[fromPage + 1].sublinks[0].link
  } else {
    return pages[fromPage].sublinks[fromSubpage + 1].link
  }
}

const getBackLink = (fromPage, fromSubpage) => {
  if (fromSubpage === 0) {
    //first subpage
    if (pages[fromPage - 1].link) {
      return pages[fromPage - 1].link
    } else {
      let prevItem =
        pages[fromPage - 1].sublinks[pages[fromPage - 1].sublinks.length - 1]
      if (prevItem.quizLink) {
        return prevItem.quizLink + "-5/"
      } else {
        return prevItem.link
      }
    }
  } else {
    if (fromSubpage === undefined)
      return pages[fromPage - 1].sublinks[
        pages[fromPage - 1].sublinks.length - 1
      ].link
    //last page
    else return pages[fromPage].sublinks[fromSubpage - 1].link
  }
}

const getCurrentLink = (fromPage, fromSubpage) => {
  const fromPageNull =
    fromPage < 0 || fromPage === null || fromPage === undefined
  const fromSubpageNull =
    fromSubpage < 0 || fromSubpage === null || fromSubpage === undefined
  if (fromPageNull && fromSubpageNull) return null
  if (fromSubpageNull) {
    return pages[fromPage].link
  } else {
    return pages[fromPage].sublinks[fromSubpage].link
  }
}

const getNextQuizLink = (fromPage, fromSubpage, questionNumber) =>
  getCurrentLink(fromPage, fromSubpage).substring(
    0,
    getCurrentLink(fromPage, fromSubpage).length - 2
  ) +
  (questionNumber + 1) +
  "/"

const getPreviousQuizLink = (fromPage, fromSubpage, questionNumber) =>
  getCurrentLink(fromPage, fromSubpage).substring(
    0,
    getCurrentLink(fromPage, fromSubpage).length - 2
  ) +
  (questionNumber - 1) +
  "/"

const getNextTitle = (fromPage, fromSubpage) => {
  if (fromSubpage === undefined)
    return pages[fromPage].buttonText
      ? pages[fromPage].buttonText
      : getHeaderTitles(fromPage + 1).headerTitle

  if (!pages[fromPage].sublinks[fromSubpage + 1]) {
    return pages[fromPage + 1].buttonText
      ? pages[fromPage + 1].buttonText
      : getHeaderTitles(fromPage + 1).headerTitle
  } else {
    return pages[fromPage].sublinks[fromSubpage + 1].buttonText
      ? pages[fromPage].sublinks[fromSubpage + 1].buttonText
      : pages[fromPage].sublinks[fromSubpage + 1].title.substring(3).trim()
  }
}

const getHeaderTitles = (fromPage, fromSubpage) => {
  const titles = pages[fromPage].title.split(":")
  return {
    headerTitle: titles[1] ? titles[1].trim() : "",
    headerSubtitle: titles[0] ? titles[0].trim() : "",
    fromPage,
    fromSubpage,
  }
}
export default pages
export {
  getBackLink,
  getNextLink,
  getCurrentLink,
  getNextTitle,
  getNextQuizLink,
  getPreviousQuizLink,
  getHeaderTitles,
}
