import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { color, fontFamily, fontSize, fontWeight, height, space, width } from "styled-system"
import { Box } from "reflexbox"

const Button = styled("button")`
    ${space}
    ${fontSize}
    ${fontFamily}
    ${fontWeight}
    ${color}
    ${width}
    ${height}
    text-transform: uppercase;
    outline: none;
    position: relative;
  
`

const RightBg = ({
                   borderColor = css`
    background-color: black;
  `,
                 }) => (
  <>
    <div
      css={css`
        width: 5px;
        height: 1px;
        position: absolute;
        top: 5px;
        left: 100%;
        ${borderColor}
      `}
    ></div>
    <div
      css={css`
        width: 1px;
        height: 100%;
        position: absolute;
        top: 5px;
        left: calc(100% + 5px);
        ${borderColor}
      `}
    ></div>
    <div
      css={css`
        width: 1px;
        height: 5px;
        position: absolute;
        top: 100%;
        left: 5px;
        ${borderColor}
      `}
    ></div>
    <div
      css={css`
        width: calc(100% + 1px);
        height: 1px;
        position: absolute;
        top: calc(100% + 5px);
        left: 5px;
        ${borderColor}
      `}
    ></div>
  </>
)

const LeftBg = ({
                  borderColor = css`
    background-color: black;
  `,
                }) => (
  <>
    <div
      css={css`
        width: 5px;
        height: 1px;
        position: absolute;
        top: 5px;
        left: -5px;
        ${borderColor}
      `}
    ></div>
    <div
      css={css`
        width: 1px;
        height: 100%;
        position: absolute;
        top: 5px;
        left: -5px;
        ${borderColor}
      `}
    ></div>
    <div
      css={css`
        width: 1px;
        height: 5px;
        position: absolute;
        top: 100%;
        left: calc(100% - 5px);
        ${borderColor}
      `}
    ></div>
    <div
      css={css`
        width: calc(100% + 1px);
        height: 1px;
        position: absolute;
        top: calc(100% + 5px);
        left: -5px;
        ${borderColor}
      `}
    ></div>
  </>
)

export default ({ children, disabled, ...props }) => {
  const colorName = props.bg ? props.bg : "black"

  return (
    <Box p={"0px"}>
      <Box
        as="div"
        fontSize={3}
        color={"white"}
        px={["4", "6", "7"]}
        bg={colorName}
        height={["40px", "48px"]}
        minHeight={["40px", "48px"]}
        css={css`
          cursor: pointer;
          border: none;
          overflow: visible;
          position: relative;
          outline: none;
          opacity: ${disabled ? 0.5 : 1};

          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
        `}
        fontFamily="AdineuePROBlackWeb"
        {...props}
      >
        <Box
          css={css`
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          `}
        >
          {props.leftBackground ? <LeftBg/> : <RightBg/>}
        </Box>
        <Box
          css={css`
            text-transform: uppercase;
          `}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}
