import React, { useCallback, useEffect, useState } from "react"
import { ThemeProvider } from "emotion-theming"
import { css, Global } from "@emotion/core"
import { Box, Flex } from "reflexbox"
import emotionNormalize from "emotion-normalize"
import Sidebar from "react-sidebar"
import { Provider } from "mobx-react"
import { Location } from "@reach/router"

import "pure-react-carousel/dist/react-carousel.es.css"
import "react-circular-progressbar/dist/styles.css"

import AdineuePROCondBlackSrc from "~/fonts/adineuePROCond-Black.otf"
import AdineuePROCondRegularSrc from "~/fonts/adineuePROCond-Regular.otf"

import AdineuePRORegularWebSrc from "~/fonts/adineuePRO-RegularWeb.woff"
import AdineuePRORegularItalicWebSrc from "~/fonts/adineuePRO-RegularItalicWeb.woff"
import AdineuePROBoldWebSrc from "~/fonts/adineuePRO-BoldWeb.woff"
import AdineuePROBoldItalicWebSrc from "~/fonts/adineuePRO-BoldItalicWeb.woff"
import AdineuePROBlackWebSrc from "~/fonts/adineuePRO-BlackWeb.woff"
import AdineuePROBlackItalicWebSrc from "~/fonts/adineuePRO-BlackItalicWeb.woff"

import PermanentMarkerRegularSrc from "~/fonts/PermanentMarker-Regular.ttf"

import { theme } from "~/components/common/theme"
import Header from "~/components/common/Header"
import Footer from "~/components/common/Footer"
import SidebarContent from "~/components/common/Menu"
import { useBottomScrollListener } from "react-bottom-scroll-listener"
import useWindowDimensions from "~/services/useWindowsDimensions"
import { progress } from "~/services/mobxStore"
import WelcomeBack from "~/components/common/WelcomeBack"
import Seo from "~/components/common/Seo"
import localStorageMemory from "localstorage-memory"

const windowGlobal = typeof window !== "undefined" && window
const localAdapter = windowGlobal
  ? windowGlobal.localStorage
  : localStorageMemory

let bottomScrollListener = useBottomScrollListener
let windowDimensions = useWindowDimensions
if (!windowGlobal) {
  bottomScrollListener = () => null
  windowDimensions = () => {
    return { height: 0, width: 0 }
  }
}

const Layout = ({
                  children,
                  footer,
                  header = {},
                  overflow,
                  myPage,
                  mySubpage,
                  welcome = true,
                }) => {
  const alreadyVisited =
    myPage < progress.maxPage ||
    (myPage == progress.maxPage && mySubpage < progress.maxSubpage)
  const [scrolledToBottom, setScrolledToBottom] = useState(
    windowGlobal ? alreadyVisited : true,
  )
  const [menuOpen, setMenuOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(true)

  const handleOnDocumentBottom = useCallback(() => {
    setScrolledToBottom(true)
  })
  const scrollRef = bottomScrollListener(handleOnDocumentBottom, 300, 0)

  const { height } = windowDimensions()
  useEffect(() => {
    if (Math.abs(height - scrollRef.current.scrollHeight) < 400)
      setScrolledToBottom(true)
  }, [])

  header.setMenuOpen = setMenuOpen
  header.menuOpen = menuOpen

  return (
    <>
      <Provider progress={progress}>
        <ThemeProvider theme={theme}>
          <Sidebar
            sidebar={
              <SidebarContent setMenuOpen={setMenuOpen} menuOpen={menuOpen}/>
            }
            open={menuOpen}
            onSetOpen={setMenuOpen}
            pullRight={true}
            styles={{
              sidebar: { background: "black" },
            }}
          >
            <Global
              styles={css`
                ${emotionNormalize}

                body,
                html {
                  margin: 0;
                  padding: 0;
                }
                * {
                  box-sizing: border-box;
                }
                #___gatsby,
                #gatsby-focus-wrapper,
                main,
                html,
                body {
                  height: 100%;
                }
                body {
                  overflow: hidden;
                }
                @font-face {
                  font-family: "AdineuePROCondBlack";
                  src: url(${AdineuePROCondBlackSrc});
                }

                @font-face {
                  font-family: "AdineuePROCondRegular";
                  src: url(${AdineuePROCondRegularSrc});
                }

                @font-face {
                  font-family: "AdineuePRORegularWeb";
                  src: url(${AdineuePRORegularWebSrc});
                }

                @font-face {
                  font-family: "AdineuePRORegularItalicWeb";
                  src: url(${AdineuePRORegularItalicWebSrc});
                }

                @font-face {
                  font-family: "AdineuePROBoldItalicWeb";
                  src: url(${AdineuePROBoldItalicWebSrc});
                }

                @font-face {
                  font-family: "AdineuePROBoldWeb";
                  src: url(${AdineuePROBoldWebSrc});
                }

                @font-face {
                  font-family: "AdineuePROBlackWeb";
                  src: url(${AdineuePROBlackWebSrc});
                }

                @font-face {
                  font-family: "AdineuePROBlackItalicWeb";
                  src: url(${AdineuePROBlackItalicWebSrc});
                }

                @font-face {
                  font-family: "PermanentMarkerRegular";
                  src: url(${PermanentMarkerRegularSrc});
                }

                li {
                  margin-top: 15px;
                  line-height: 1.5em;
                }

                button {
                  cursor: pointer;
                }

                a,
                a:visited,
                a:active {
                  text-decoration: none;
                  -webkit-tap-highlight-color: transparent;
                }

                a:hover {
                  text-decoration: underline;
                }

                .carousel__dot {
                  background-color: gray !important;
                }

                .carousel__dot--selected {
                  background-color: black !important;
                }

                .collapse-css-transition {
                  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
                }
              `}
            />
            <Seo/>
            <Flex flexDirection="column" height="100%">
              <Location>
                {({ location }) => {
                  let nullModal = true

                  const href = "" + location.href
                  let visited = JSON.parse(
                    localAdapter.getItem("up2us:visited") || "{}",
                  )
                  if (visited[href]) {
                    nullModal = false
                  } else {
                    visited[href] = true
                    localAdapter.setItem(
                      "up2us:visited",
                      JSON.stringify(visited),
                    )
                  }
                  return (
                    <WelcomeBack
                      isOpen={modalOpen}
                      disable={!welcome || nullModal}
                      close={() => setModalOpen(false)}
                    />
                  )
                }}
              </Location>

              <Header {...header} />
              <Box
                as="main"
                flexGrow={1}
                flexShring={1}
                // position="relative"
                // height={"100%"}
                overflow={overflow ? overflow : "auto"}
                ref={scrollRef}
              >
                {children}
              </Box>
              {footer && (
                <Footer scrolledToBottom={scrolledToBottom} {...footer} />
              )}
            </Flex>
          </Sidebar>
        </ThemeProvider>
      </Provider>
    </>
  )
}

export default Layout
