import { Box, Flex } from "reflexbox"
import { Link } from "gatsby"
import { Heading } from "~/components/common/Typography"
import { css } from "@emotion/core"
import Burger from "~/images/burger-menu.svg"
import React, { useState } from "react"
import pages from "~/services/pages"
import { Accordion, AccordionLinks } from "~/components/common/Accordion"
import { inject, observer } from "mobx-react"

const SidebarContent = inject("progress")(
  observer(({ setMenuOpen, menuOpen, progress }) => {
    const isOpenInitialState = {}
    pages.forEach((_, index) => (isOpenInitialState[index] = false))
    const [isOpen, setIsOpen] = useState(isOpenInitialState)

    let maxPage = progress.maxPage
    let maxSubpage = progress.maxSubpage
    let curPage = progress.curPage
    let curSubpage = progress.curSubpage

    const getStatus = (maxPage, maxSubpage, index) => {
      if (maxPage > index) {
        return { status: "complete" }
      }
      if (maxPage < index) {
        return { status: "lock" }
      }
      if (maxPage == index) {
        if (!pages[index].sublinks) return { status: "complete" }
        const subNum = pages[index].sublinks.length
        const percent =
          subNum == 0 || maxSubpage == -1
            ? 100
            : (100 / subNum) * (maxSubpage + 1)
        return { status: "current", progress: percent }
      }
    }

    return (
      <Flex position={"relative"} width={["330px", "400px", "500px"]}>
        <Flex flexDirection={"column"} width={"100%"}>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            pl={["16px", "32px"]}
            pr={["16px"]}
            width={"100%"}
            height={["54px", "80px"]}
          >
            <Link
              to={"/"}
              // css={css`
              //   text-decoration: none;
              //
              //   &:hover {
              //     text-decoration: none;
              //   }
              // `}
            >
              <Heading
                color="white"
                fontFamily={"AdineuePROBlackWeb"}
                fontSize={["16px", "24px"]}
                lineHeight={["19px", "29px"]}
              >
                Keep&nbsp;Girls&nbsp;in&nbsp;Sport
              </Heading>
            </Link>

            <Box width={["30px", "60px"]} height={["30px", "60px"]} ml="auto">
              <a
                href={"#"}
                onClick={() => {
                  setMenuOpen(!menuOpen)
                }}
                css={css`
                  width: 100%;
                  height: 100%;
                `}
              >
                <Burger alt="Menu"></Burger>
              </a>
            </Box>
          </Flex>
          <Flex
            flexDirection={"column"}
            alignItems="center"
            justifyContent="space-between"
            px={["16px", "32px"]}
            width={"100%"}
          >
            <Accordion
              isOpen={isOpen[1000]}
              onToggle={() =>
                setIsOpen(s => {
                  return Object.assign({}, s, { [1000]: !s[1000] })
                })
              }
              heading={"ABOUT THE CURRICULUM"}
              disabled={true}
              link={"/about/"}
              status={{ status: "complete" }}
            >
              <AccordionLinks page={7} status={{ status: "complete" }} />
            </Accordion>
            {pages
              .filter(p => !p.hideMenu)
              .map((page, index) => {
                return (
                  <Accordion
                    key={page.title}
                    isOpen={isOpen[index]}
                    onToggle={() =>
                      setIsOpen(s => {
                        return Object.assign({}, s, { [index]: !s[index] })
                      })
                    }
                    heading={page.title}
                    disabled={page.disabled}
                    link={page.link}
                    status={getStatus(maxPage, maxSubpage, index)}
                  >
                    <AccordionLinks
                      page={index}
                      links={page.sublinks}
                      status={getStatus(maxPage, maxSubpage, index)}
                    />
                  </Accordion>
                )
              })}
            <Accordion
              isOpen={isOpen[999]}
              onToggle={() =>
                setIsOpen(s => {
                  return Object.assign({}, s, { [999]: !s[999] })
                })
              }
              heading={"RESOURCES"}
              disabled={true}
              link={"/resources/"}
              status={{ status: "complete" }}
            >
              <AccordionLinks page={7} status={{ status: "complete" }} />
            </Accordion>
          </Flex>
        </Flex>
      </Flex>
    )
  })
)

export default SidebarContent
